<template>
    <section class="pagosPendientes mt-4 ml-4" v-loading="loading">
        <titulo2 titulo="Pagos Pendientes" @inputSearch="listarPagos" />
        <div class="row">
            <div class="col-12">
                <el-table :data="pagos" height="550" style="width: 100%">
                    <el-table-column sortable prop="agrupacion" label="Grupo de viviendas">
                      <template slot-scope="scope">
                            <div class="">
                                <p> {{ scope.row.agrupacion.nombre }} [{{ scope.row.agrupacion.abreviatura }}] </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="vivienda" label="Vivienda" >
                      <template slot-scope="scope">
                            <div class="">
                                <p> {{ scope.row.vivienda.nombre }} {{ scope.row.vivienda.abreviatura }} </p>
                            </div>
                      </template>
                    </el-table-column>
                    <el-table-column sortable prop="pago_mes" label="Mes" >
                      <template slot-scope="scope">
                            <div class="">
                                <p v-if="! _.isEmpty(scope.row.pago_mes)"> {{ scope.row.pago_mes.fecha | helper-fecha('MMM YYYY') }} </p>
                            </div>
                      </template>
                    </el-table-column>
                    <el-table-column sortable prop="valor" label="Valor">
                        <template slot-scope="scope">
                            <div class="">
                                <p v-if="! _.isEmpty(scope.row.pago_mes)"> {{ convertMoney(scope.row.pago_mes.valor) }} </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="estado" label="Estado" width="120">
                        <template slot-scope="scope">
                            <div class="">
                                <!-- <p v-if="scope.row.estado === 0" class="estado-pago br-4 px-2 f-14 text-center">Sin verificar</p> -->
                                <!-- <p v-if="scope.row.estado === 1" class="estado-nopago br-4 px-2 f-14 text-center">Sin pagar</p> -->
                                <p class="br-4 px-2 f-14 text-center" :class="[ scope.row.estado === 21 ? 'estado-pago' : 'estado-nopago' ]">
                                    Estado
                                </p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="estado" width="160">
                        <template slot-scope="scope">
                            <div class="">
                                <button v-if="scope.row.estado === 21" class="btn boton-verificar br-20 lh-17 px-3 f-14 text-center py-1 text-white" @click="verificarPago(scope.row.id)" >
                                    Verificar pago
                                </button>
                                <button v-if="scope.row.estado === 31" class="btn boton-registrarpago br-20 lh-17 px-3 f-14 text-center py-1 text-white" @click="registrarPago(scope.row.id)" >
                                    Registrar pago
                                </button>
                            </div>
                        </template>
                    </el-table-column>
                    <div class="row" slot="empty">
                        <div class="col-12 text-center">
                            <img
                                src="/img/generales/sin_resultados.svg"
                                alt=""
                                width="120"
                                height="120"
                            />
                            <p
                                class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3"
                            >
                                No hemos encontrado coincidencias con tu
                                búsqueda
                            </p>
                        </div>
                    </div>
                </el-table>
            </div>
        </div>
        <!-- Partials -->
        <modal-registrar-pago ref="abrirModalRegistrarPago" @refresh="listarPagos" />
    </section>
</template>

<script>
import Viviendas from '~/services/viviendas'
export default {
  components:{
    modalRegistrarPago: () => import('./partials/modalRegistrarPago')
  },
    data(){
      return{
        loading: false,
        pagos:[],
        search: ''
      }
    },
    created(){
      this.listarPagos()
    },
    methods:{
      registrarPago(id){
        this.$refs.abrirModalRegistrarPago.toggle(id)
      },
      verificarPago(id){
        this.$router.push({ name: 'verificar.pago', params:{id_pago:id} })
      },
      async listarPagos(search){
        this.search = search
        try {
          this.loading = true
          let params = {
            search : this.search
          }
          const {data} = await Viviendas.listarPagos(params)
          this.pagos = data.data
          this.loading = false
        } catch (e){
            console.log(e)
            this.loading = false
        }          
      },

    }
}
</script>

<style lang="scss" scoped>
.pagosPendientes {
    .estado {
        &-pago {
            background: var(--color-db);
            color: var(--text-muted);
        }
        &-nopago {
            background: #ffb2c2;
            color: #ffffff;
        }
    }
    .boton {
        &-verificar {
            height: 28px;
            background: var(--color-general);
        }
        &-registrarpago {
            height: 28px;
            background: #000;
        }
    }
}
.text-sin-datos {
    color: var(--sin-datos);
    max-width: 286px;
}
</style>